/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect } from "react";
import Overlay from "ol/Overlay";
import MapContext from "../MapContext";
import "./popup.css";

const Popup = () => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const container = document.getElementById("popup");
    const content = document.getElementById("popup-content");
    const closer = document.getElementById("popup-closer");

    const overlay = new Overlay({
      stopEvent: true,
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };

    map.on("singleclick", function (evt) {
      const { coordinate } = evt;
      const features = map.getFeaturesAtPixel(evt.pixel);
      if (features.length < 1) {
        overlay.setPosition(undefined);
        closer.blur();
        content.innerHTML = "";
        return;
      }
      let properties = features[0].getProperties();
      const clusterFeatures = features[0].get("features");
      if ("cases" in properties) {
        delete properties.geometry;
        delete properties.layer;
        content.innerHTML = `<table style="width:100%;border-collapse:unset;">
        ${Object.keys(properties).reduce(
          (str, key) =>
            `${str}
          <tr>
            <td>${key}</td>
            <td><b>${properties[key]}</b>
            </td>
          </tr>`,
          ""
        )}
      </table>`;
        overlay.setPosition(coordinate);
        evt.stopPropagation();
      } else if ("value" in properties) {
        // delete properties.geometry;
        delete properties.layer;
        content.innerHTML = `<table style="width:100%;border-collapse:unset;">
        ${Object.keys(properties).reduce(
          (str, key) =>
            `${str}
          <tr>
            <td>${key}</td>
            <td><b>${properties[key]}</b>
            </td>
          </tr>`,
          ""
        )}
      </table>`;
        overlay.setPosition(coordinate);
        evt.stopPropagation();
      } else if (
        clusterFeatures &&
        clusterFeatures.length === 1 &&
        "province_name" in clusterFeatures[0].getProperties()
      ) {
        properties = clusterFeatures[0].getProperties();
        delete properties.geometry;
        delete properties.id;
        delete properties.province_id;
        delete properties.district_id;
        delete properties.local_level_id;
        content.innerHTML = `
        <center><h5>College Details</h5></center>
        <table style="width:100%;border-collapse:unset;">
        <tr>
            <td>College Name: </td>
            <td><b>${properties.name}</b>
            </td>
          </tr>       
      </table>
      <center><h5>Address Details</h5></center>
        <table style="width:100%;border-collapse:unset;">
        <tr>
            <td>Province: </td>
            <td><b>${properties.province_name}</b>
            </td>
          </tr> 
          <tr>
            <td>District: </td>
            <td><b>${properties.district_name}</b>
            </td>
          </tr>  
          <tr>
            <td>Local Level: </td>
            <td><b>${properties.local_level_name}</b>
            </td>
          </tr>       
      </table>
      `;
        overlay.setPosition(coordinate);
        evt.stopPropagation();
      } else {
        overlay.setPosition(undefined);
      }
      map.addOverlay(overlay);
      return () => {
        if (map) {
          map.removeOverlay(overlay);
        }
      };
    });
  }, [map]);

  return (
    <div id="popup" className="ol-popup">
      <a href={() => {}} id="popup-closer" className="ol-popup-closer" />
      <div id="popup-content" className="is-overflow" />
    </div>
  );
};

export default Popup;
