/* eslint-disable jsx-a11y/anchor-has-content */
import React,{ useContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "ol/ol.css";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Polyline from "ol/format/Polyline";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Overlay from "ol/Overlay";
import { Fill, Style, Icon, Circle, Stroke, Text } from "ol/style";
import MapContext from "../MapContext";
import mapIcon from "../../../assets/icons/mapicon.png";
import { useDispatch, useSelector } from "react-redux";
import { setRoute, resetRoute } from "../../../slices/route";
import { transformExtent } from "ol/proj";
import { bbox } from "@turf/turf";
import GeoJSON from "ol/format/GeoJSON";
import { TU_BASE_URL } from "../../../constants";
import { useState } from "react";
import { fromLonLat, transform } from "ol/proj";
import dots from "../../../assets/icons/DOTS.png";

const vectorSource = new VectorSource({});
const vectorLayer = new Vector({
  zIndex: 111,
  source: vectorSource,
});

const nearestHfsSource = new VectorSource({});
const nearestHfsLayer = new Vector({
  zIndex: 222,
  source: nearestHfsSource,
});
const userSource = new VectorSource({});
const userLayer = new Vector({
  zIndex: 999,
  source: userSource,
  style: new Style({
    image: new Circle({
      radius: 5,
      fill: new Fill({
        color: "#00fa00",
      }),
      stroke: new Stroke({
        width: 5,
        color: "#0000fa88",
      }),
    }),
    text: new Text({
      text: "You are here",
      font: '15px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
      offsetY: 16,
    }),
  }),
});

function RouteLayer() {
  const dispatch = useDispatch();
  const graphhopperRoute = useSelector((state) => state.route.graphhopperRoute);
  const layerVisibility = useSelector((state) => state.layer);
  const { map } = useContext(MapContext);
  const [userLocation, setUserLocation] = useState(null);
  const [hfLocation, setHfLocation] = useState(null);

  useEffect(() => {
    if (!map) return;
    map.addLayer(vectorLayer);
    map.addLayer(userLayer);
    map.addLayer(nearestHfsLayer);

    return () => {
      map.removeLayer(vectorLayer);
      map.removeLayer(userLayer);
      map.removeLayer(nearestHfsLayer);
    };
  }, [map]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;
      setUserLocation({
        lat,
        lon,
      });
      const userMarker = new Feature({
        type: "icon",
        geometry: new Point(fromLonLat([lon, lat], "EPSG:3857")),
      });
      console.log(userMarker);
      userSource.clear();
      userSource.addFeatures([userMarker]);
    });
    return () => {};
  }, []);
  useEffect(() => {
    if (!map) return;
    const polyline = graphhopperRoute.paths[0].points;

    if (polyline) {
      const route = new Polyline({
        geometryLayout: "XY",
      }).readGeometry(polyline, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
      map.getView().fit(route);

      const routeFeature = new Feature({
        type: "route",
        geometry: route,
      });
      const geoMarker = new Feature({
        type: "geoMarker",
        geometry: new Point(route.getCoordinateAt(0)),
      });
      const startMarker = new Feature({
        type: "icon",
        geometry: new Point(route.getCoordinateAt(0)),
      });
      const endMarker = new Feature({
        type: "icon",
        geometry: new Point(route.getCoordinateAt(1)),
      });
      const styles = {
        route: new Style({
          stroke: new Stroke({
            width: 6,
            color: [237, 212, 0, 0.8],
          }),
        }),
        icon: new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: mapIcon,
            scale: 0.05,
          }),
        }),
        geoMarker: new Style({
          image: new Circle({
            radius: 7,
            fill: new Fill({ color: "black" }),
            stroke: new Stroke({
              color: "white",
              width: 2,
            }),
          }),
        }),
      };

      let animating = false;

      const vectorStyle = function (feature) {
        if (animating && feature.get("type") === "geoMarker") {
          return null;
        }
        return styles[feature.get("type")];
      };
      vectorLayer.setStyle(vectorStyle);
      vectorSource.clear();
      vectorSource.addFeatures([
        routeFeature,
        geoMarker,
        startMarker,
        endMarker,
      ]);
    } else {
      vectorSource.clear();
      const extent = nearestHfsSource.getExtent();
      if (!(extent.includes(Infinity) || extent.includes(-Infinity))) {
        map.getView().fit(extent, map.getSize());
      }
    }
    return () => {};
  }, [graphhopperRoute.paths, map]);

  useEffect(() => {
    if (!map) return;
    const container = document.getElementById("popup");
    const closer = document.getElementById("popup-closer");
    const content = document.getElementById("popup-content");
    const contentNode = ReactDOM.createRoot(content);

    const overlay = new Overlay({
      stopEvent: true,
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
    map.addOverlay(overlay);
    map.on("singleclick", (e) => {
      const { pixel, coordinate } = e;
      nearestHfsLayer.getFeatures(pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          const name = clickedFeatures[0].getProperties()["name"];
          const pk = clickedFeatures[0].getProperties()["pk"];
          const [lon, lat] = transform(coordinate, "EPSG:3857", "EPSG:4326");
          contentNode.render(
            <>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "unset",
                }}
              >
                <tbody>
                  <tr>
                    <td>Name: </td>
                    <td>
                      <b>{name}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              {pk === hfLocation?.pk ? (
                <button
                  onClick={() => {
                    setHfLocation(null);
                    dispatch(resetRoute());
                    overlay.setPosition(undefined);
                  }}
                  type="button"
                  className="btn btn-danger"
                >
                  Clear Route
                </button>
              ) : (
                <button
                  onClick={() => {
                    setHfLocation({
                      lon,
                      lat,
                      pk,
                    });
                    overlay.setPosition(undefined);
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  Go Here
                </button>
              )}
            </>
          );
          overlay.setPosition(coordinate);
        } else {
          overlay.setPosition(undefined);
        }
      });
    });

    return () => {};
  }, [dispatch, hfLocation, map]);

  useEffect(() => {
    if (userLocation) {
      const params = {};
      params["lat"] = userLocation.lat;
      params["lon"] = userLocation.lon;
      fetch(TU_BASE_URL + "nearclzs/?" + new URLSearchParams(params)).then(
        (response) => {
          response.json().then((result) => {
            nearestHfsSource.clear();
            nearestHfsSource.addFeatures(
              new GeoJSON({
                featureProjection: "EPSG:3857",
              }).readFeatures(result)
            );
            nearestHfsLayer.setStyle((feature, resolution) => {
              const styles = [];
              styles.push(
                new Style({
                  image: new Icon({
                    src: dots,
                    anchor: [0, 0],
                    anchorXUnits: "pixels",
                    anchorYUnits: "pixels",
                    scale: [0.9, 0.9],
                  }),
                })
              );
              return styles;
            });
            const extent = transformExtent(
              bbox(result),
              "EPSG:4326",
              "EPSG:3857"
            );
            map.getView().fit(extent, map.getSize());
          });
        }
      );
    }
  }, [
    dispatch,
    layerVisibility.cltrVisible,
    layerVisibility.dotsVisible,
    layerVisibility.drVisible,
    layerVisibility.geneVisible,
    layerVisibility.hstlVisible,
    layerVisibility.mcVisible,
    layerVisibility.sdrVisible,
    map,
    userLocation,
  ]);

  useEffect(() => {
    if (userLocation && hfLocation) {
      const lat1 = userLocation.lat;
      const lon1 = userLocation.lon;
      const lat2 = hfLocation.lat;
      const lon2 = hfLocation.lon;
      fetch(
        `${TU_BASE_URL}routefromlatlon/?lat1=${lat1}&lon1=${lon1}&lat2=${lat2}&lon2=${lon2}`
      ).then((response) => {
        response.json().then((result) => {
          dispatch(setRoute(result));
        });
      });
    }
    return () => {};
  }, [dispatch, hfLocation, map, userLocation]);
  return (
    <div id="popup" className="ol-popup">
      <a href={() => {}} id="popup-closer" className="ol-popup-closer" />
      <div id="popup-content" className="is-overflow" />
    </div>
  );
}

export default RouteLayer;
