import React from "react";
import AdminSelectBar from "./AdminSelectBar";

function Topbar() {
  return (
    <>
      <AdminSelectBar />
      {/* <TypeToggleBar /> */}
    </>
  );
}

export default Topbar;
