import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminFilter: {
    province_id: null,
    district_id: null,
    local_level_id: null,
    province_ids: [],
    district_ids: [],
    local_level_ids: [],
    fiscal_year_id: null,
  },
  provinces: [],
  districts: [],
  local_levels: [],
  // fiscal_years: [],
  hfs_master: {
    dots: [],
  },
  hfs_filtered: {
    filters: {
      provinces: [],
      districts: [],
      local_levels: [],
    },
    dots: [],
  },
};

const adminValues = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setHFMaster(state, { payload }) {
      state.hfs_master = { ...state.hfs_master, ...payload };
      Object.entries(payload).forEach(([key, value]) => {
        const newObj = { ...payload };
        if (state.adminFilter.local_level_ids.length > 0) {
          newObj[key] = value.filter((e) =>
            state.adminFilter.local_level_ids.includes(
              e.properties.local_level_id
            )
          );
        } else if (state.adminFilter.district_ids.length > 0) {
          newObj[key] = value.filter((e) =>
            state.adminFilter.district_ids.includes(e.properties.district_id)
          );
        } else if (state.adminFilter.province_ids.length > 0) {
          newObj[key] = value.filter((e) =>
            state.adminFilter.province_ids.includes(e.properties.province_id)
          );
        }
        state.hfs_filtered = { ...state.hfs_filtered, ...newObj };
      });
    },
    setHFFiltered(state, { payload }) {
      state.hfs_master = { ...state.hfs_filtered, payload };
    },
    // setFiscalYears(state, { payload }) {
    //   state.fiscal_years = payload;
    // },
    // setFiscalYearId(state, { payload }) {
    //   state.adminFilter.fiscal_year_id = payload;
    // },
    setProvince(state, { payload }) {
      if (state.adminFilter.province_id !== payload) {
        state.adminFilter.province_id = payload;
        state.adminFilter.district_id = null;
        state.adminFilter.local_level_id = null;
      }
    },
    setDistrict(state, { payload }) {
      if (state.adminFilter.district_id !== payload) {
        state.adminFilter.district_id = payload;
        state.adminFilter.local_level_id = null;
      }
    },
    setLocalLevel(state, { payload }) {
      state.adminFilter.local_level_id = payload;
    },
    setProvinceIds(state, { payload }) {
      state.adminFilter.province_ids = payload;
      state.adminFilter.district_ids = [];
      state.adminFilter.local_level_ids = [];
      const newObj = { ...state.hfs_master };
      console.log(state.hfs_master)
      Object.entries(state.hfs_master).forEach(([key, value]) => {

        if (payload.length > 0) {
          newObj[key] = value.filter((e) =>
              payload.includes(e.properties.province_id)
          );
        }
        state.hfs_filtered = { ...state.hfs_filtered, ...newObj };
      });
    },
    setDistrictIds(state, { payload }) {
      state.adminFilter.district_ids = payload;
      state.adminFilter.local_level_ids = [];
      const newObj = { ...state.hfs_master };
      Object.entries(state.hfs_master).forEach(([key, value]) => {
        if (payload.length > 0) {
          newObj[key] = value.filter((e) =>
            payload.includes(e.properties.district_id)
          );
        } else if (state.adminFilter.province_ids > 0) {
          newObj[key] = value.filter((e) =>
            state.adminFilter.province_ids.includes(e.properties.province_id)
          );
        }
        state.hfs_filtered = { ...state.hfs_filtered, ...newObj };
      });
    },
    setLocalLevelIds(state, { payload }) {
      state.adminFilter.local_level_ids = payload;
      const newObj = { ...state.hfs_master };
      Object.entries(state.hfs_master).forEach(([key, value]) => {
        if (payload.length > 0) {
          newObj[key] = value.filter((e) =>
            payload.includes(e.properties.local_level_id)
          );
        } else if (state.adminFilter.district_ids > 0) {
          newObj[key] = value.filter((e) =>
            state.adminFilter.district_ids.includes(e.properties.district_id)
          );
        } else if (state.adminFilter.province_ids > 0) {
          newObj[key] = value.filter((e) =>
            state.adminFilter.province_ids.includes(e.properties.province_id)
          );
        }
        state.hfs_filtered = { ...state.hfs_filtered, ...newObj };
      });
    },
    setProvinceList(state, { payload }) {
      state.provinces = payload;
    },
    setDistrictList(state, { payload }) {
      state.districts = payload;
    },
    setLocalLevelList(state, { payload }) {
      state.local_levels = payload;
    },
  },
});

export const {
  setDistrict,
  setLocalLevel,
  setProvince,
  setProvinceList,
  setDistrictList,
  setLocalLevelList,
  setDistrictIds,
  setProvinceIds,
  setLocalLevelIds,
  setFiscalYears,
  setFiscalYearId,
  setHFMaster,
  setHFFiltered,
} = adminValues.actions;

export default adminValues.reducer;
