/* eslint-disable consistent-return */
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import { useContext, useEffect } from "react";
import LayerSwitcher from "ol-layerswitcher";
import LayerGroup from "ol/layer/Group";
import MapContext from "../MapContext";
import { osm, satelliteMap, topoMap } from "../Layers/BaseLayers";

const LayerSwitcherControl = ({ layerSource }) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    const baseMaps = new LayerGroup({
      title: "Base maps",
      layers: layerSource,
    });

    const layerSwitcher = new LayerSwitcher({
      reverse: true,
      groupSelectStyle: "group",
    });

    map.addLayer(baseMaps);
    map.controls.push(layerSwitcher);

    return () => {
      map.removeLayer(baseMaps);
      map.controls.remove(layerSwitcher);
    };
  }, [map]);

  return null;
};

export default LayerSwitcherControl;

LayerSwitcherControl.defaultProps = {
  layerSource: [osm(), topoMap(), satelliteMap()],
};
