/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,faInstitution,faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
import {
  faBuffer
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import NavLink from "../NavLink/NavLink";
import { BASE_URL } from "../../constants";

function Sidebar() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    function checkToken() {
      const token = localStorage.getItem("token");

      setToken(token);
    }

    window.addEventListener("storage", checkToken);

    return () => {
      window.removeEventListener("storage", checkToken);
    };
  }, []);

  return (
    <div
      id="sidebar-column"
      className="d-flex flex-column flex-shrink-0 p-1 text-white bg-dark"
      style={{ width: "100%", height: "100%" }}
    >
      <Link
        to="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
      >
        <span className="fs-4 px-2">TU Geoportal</span>
      </Link>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
            <NavLink toLink="/">
              <FontAwesomeIcon className="px-2" icon={faInstitution} />
              Colleges
            </NavLink>
            <NavLink toLink="/routemap">
              <FontAwesomeIcon className="px-2" icon={faMapLocationDot} />
              Route Map
            </NavLink>
            <NavLink toLink="/distancemap">
              <FontAwesomeIcon className="px-2" icon={faMapLocationDot} />
              Distance Map
            </NavLink>
            <NavLink toLink="/buffermap">
              <FontAwesomeIcon className="px-2" icon={faBuffer} />
              Buffer Map
            </NavLink>
        </li>
        {token && (
          <>
          </>
        )}
      </ul>
      <hr />
      <div className="pb-4" style={{cursor:"pointer"}}>

        <FontAwesomeIcon className="px-2" icon={faRightFromBracket} />
          <a href={BASE_URL+'auth/login'} target="_blank" className="text-decoration-none text-white pl-5" rel="noreferrer">
            Log In
          </a>

        {/* <div
          style={{ cursor: "pointer" }}
          className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
          id="dropdownUser1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        > */}
          {/* <FontAwesomeIcon className="px-2" icon={faUser} /> */}
      
          {/* <strong>Guest</strong> */}
        </div>
        {/* <ul
          className="dropdown-menu dropdown-menu-dark text-small shadow"
          aria-labelledby="dropdownUser1"
        >
          <li>
            {!token ? (
                  <a href={BASE_URL+'auth/login'} target="_blank" rel="noreferrer" className="dropdown-item">
                    <FontAwesomeIcon className="px-2" icon={faRightFromBracket} />
                    Log In
                  </a>
              // <Link className="dropdown-item" to="/login">
              //   <FontAwesomeIcon className="px-2" icon={faRightFromBracket} />
              //   Log In
              // </Link>
            ) : (
              <Link
                className="dropdown-item"
                to="/login"
                onClick={() => {
                  localStorage.removeItem("token");
                  window.dispatchEvent(new Event("storage"));
                }}
              >
                <FontAwesomeIcon className="px-2" icon={faRightFromBracket} />
                Log out
              </Link>
            )}
          </li>
          
        </ul> */}
      {/* </div> */}
    </div>
  );
}

export default Sidebar;
