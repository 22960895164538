import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Map from "../components/MapOpenlayers/Map";
import { Layers } from "../components/MapOpenlayers/Layers";
import LayerSwitcherControl from "../components/MapOpenlayers/Controls/LayerSwitcherControl";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  FullScreenControl,
} from "../components/MapOpenlayers/Controls";
import ZoomControl from "../components/MapOpenlayers/Controls/ZoomControl";
import OLSidePanel from "../components/MapOpenlayers/SidePanel/SidePanelWebmap";
import Legend from "../components/MapOpenlayers/Legend/Legend";
import Scale from "../components/MapOpenlayers/Scale/Scale";
import Popup from "../components/MapOpenlayers/Popup";
import { TU_BASE_URL } from "../constants";
import PrintMapControl from "../components/MapOpenlayers/PrintMap";
import { setHFMaster } from "../slices/adminvalues";
import SDPLayer from "../components/MapOpenlayers/Layers/SDPLayer";

const mapCenter = fromLonLat([85, 28.5]);
const mapZoom = 7.2;

export default function WebMap() {
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(`${TU_BASE_URL}collegedata/`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ dots: result.features })))
    );
  }, [dispatch]);
  const mapSettings = useSelector((state) => state.mapSettings);
  return (
    <div className="row gx-0 gy-0">
      <div className="col-md-12 position-relative">
        <Map
          id="map"
          className="map"
          cssStyle={{ height: "100%", width: "100%" }}
          center={mapCenter}
          zoom={mapZoom}
        >
          <Legend />
          <Layers>
            <LayerSwitcherControl />
            <SDPLayer />
          </Layers>
          <Popup />
          <Controls>
            {mapSettings.scaleSettings.visible && <Scale />}
            <PrintMapControl title="Colleges of TU" />
            <OLSidePanel />
            <FullScreenControl />
            <ZoomControl />
          </Controls>
        </Map>
      </div>
    </div>
  );
}
