import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  healthFacilitiesVisible: true,
  districtsVisible: true,
  provincesVisible: true,
  localLevelVisible: true,
  countryVisible: false,
  roadVisible: false,
  dotsVisible: true,
  routeVisible: false,
  outreachBufferVisible: false,
  etbcasesVisible: false,
};

const layerSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    setEtbCases(state, { payload }) {
      state.etbcasesVisible = payload;
    },
    setHealth(state, { payload }) {
      state.healthFacilitiesVisible = payload;
    },
    setOutreach(state, { payload }) {
      state.outreachBufferVisible = payload;
    },
    setDots(state, { payload }) {
      state.dotsVisible = payload;
    },
    setDistrict(state, { payload }) {
      state.districtsVisible = payload;
    },
    setProvince(state, { payload }) {
      state.provincesVisible = payload;
    },
    setCountry(state, { payload }) {
      state.countryVisible = payload;
    },
    setLocalLevel(state, { payload }) {
      state.localLevelVisible = payload;
    },
    setRoad(state, { payload }) {
      state.roadVisible = payload;
    },
    setRoute(state, { payload }) {
      state.routeVisible = payload;
    },
  },
});

export const {
  setDistrict,
  setHealth,
  setProvince,
  setLocalLevel,
  setRoad,
  setDots,
  setRoute,
  setOutreach,
  setEtbCases,
  setCountry,
  setHome,
} = layerSlice.actions;

export default layerSlice.reducer;
