import React from "react";
import WebMap from "./pages/webmap";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import addTopBar from "./utils/addTopBar";
import addSidebar from "./utils/addSideBar";
import OutreachMap from "./pages/outreachmap";
import AdminSelectBar from "./components/Topbar/AdminSelectBar";
import RouteMap from "./pages/routemap";
import DistanceMap from "./pages/distancemap";

function App() {
  return (
    <div className="App">
      <div className="row vh-100 gx-0 gy-0">
        <Router>
          <Routes>
            <Route path="/" element={addSidebar(addTopBar(<WebMap />))} />
            <Route path="/webmap" element={addSidebar(addTopBar(<WebMap />))} />
            <Route
              path="/routemap"
              element={addSidebar(
                <div className="row flex-grow-1 gx-0 gy-0">
                  <RouteMap />
                </div>
              )}
            />
            <Route
              path="/distancemap"
              element={addSidebar(
                <div className="row flex-grow-1 gx-0 gy-0">
                  <DistanceMap />
                </div>
              )}
            />
            <Route
              path="/buffermap"
              element={addSidebar(
                <>
                  <AdminSelectBar />
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <OutreachMap />
                  </div>
                </>
              )}
            />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
